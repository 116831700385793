import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import "../globalstyle.css";
import { ImportLocationsToDB } from "../ImportToDB/ImportLocationsToDB";
import PopupMessage from "../popupmessage";

const ImportLocations = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("");

  useEffect(() => {
    setSelectedFiles(null);
  }, []);
  const showPopupfunc = (popupType, PopupMessage) => {
    setShowPopup(true);
    setPopupType(popupType);
    setPopupMessage(PopupMessage);
  };
  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = selectedFiles[0];
    const reader = new FileReader();
    if (file) {
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];

          const sheetHeaders = [];
          for (let col in firstSheet) {
            if (/^[A-Z]+\d+$/.test(col)) {
              const rowIndex = Number(col.match(/\d+/)[0]);
              if (rowIndex === 1) {
                sheetHeaders.push(firstSheet[col].v);
              }
            }
          }

          //  console.log('Headers in the Excel file:', sheetHeaders);
          const expectedColumns = [
            "store_name",
            "address",
            "city",
            "state",
            "zip_code",
            "country",
            "location_manager_name",
            "location_manager_phone",
            "area_manager_name",
            "area_manager_phone",
            "mon_to_fri_opening(24HRS)",
            "mon_to_fri_closing(24HRS)",
            "sat_to_sun_opening(24HRS)",
            "sat_to_sun_closing(24HRS)",
            "retail_code",
          ];
          const missingColumns = expectedColumns.filter(
            (header) => !sheetHeaders.includes(header)
          );
          if (missingColumns.length === 0) {
            // console.log('Excel file structure is valid');
            try {
              ImportLocationsToDB(selectedFiles[0]).then((st) => {
                if (st && st === "success") {
                  showPopupfunc("success", "Locations Imported");
                } else if (st && st === "failure") {
                  showPopupfunc(
                    "failure",
                    "Locations Import Error(Duplicates)"
                  );
                }
                // console.log("OOOO");
                setSelectedFiles(null);
              });
            } catch (error) {
              console.log(error, "Error");
              showPopupfunc("failure", "Locations Import Error");

              setSelectedFiles(null);
            }
          } else {
            //console.error('Missing columns:', missingColumns);
            showPopupfunc("failure", "Excel Missing columns");
          }
        } catch (error) {
          console.error("Error reading Excel file:", error.message);
          showPopupfunc("failure", "Error reading Excel");
        }
      };
    }
    reader.readAsArrayBuffer(file);
    // console.log("OOO");
    // console.log(selectedFiles[0]);
  };
  return (
    <>
      {showPopup && (
        <PopupMessage
          type={popupType}
          message={popupMessage}
          setVisible={setShowPopup}
        />
      )}
      <div
        style={{
          margin: "31px",
          textAlign: "center",
          width: "fit-content",
          background: "#005668",
          fontSize: "xx-large",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <a
          href={"https://aerial.intelly.uk/locations%20-%20Sample.xlsx"}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          Download Sample For Locations Import
        </a>
      </div>
      <br></br>
      <div>
        <input
          type="file"
          accept=".xlsx"
          onChange={(e) => {
            setSelectedFiles(e.target.files);
          }}
          style={{ fontSize: "22px", padding: "10px" }}
        />
        {selectedFiles ? (
          <>
            <button
              type="button"
              className="importButton"
              onClick={handleFileUpload}
              style={{ fontSize: "18px", padding: "12px" }}
            >
              Import
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default ImportLocations;
